/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family:
    'Open Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #ffffff;
  color: #666666;
  border-top: 1px solid #e4e4e4;
  p {
    margin: 0 0 5px 0;
    font-size: 0.9rem;
    &.asterisk,
    &.disclaimer {
      font-size: 0.8rem;
    }
    &.trustmark {
      display: none;
      margin: 0;
    }
  }
  a {
    color: #666666;
    text-decoration: none;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}

.fw-strong {
  font-weight: 600 !important;
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-secondary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 12px;
    margin-bottom: 12px;
    border: 0 !important;
    width: 80%;
    max-width: 650px;
    img {
      display: none;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      max-width: 40px;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family:
        'Roboto Slab',
        'Open Sans',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        'Helvetica Neue',
        Arial,
        'Noto Sans',
        sans-serif,
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'Noto Color Emoji';
      color: $theme-primary;
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      font-family: 'Open Sans', Verdana;
      font-weight: normal;
      font-style: italic;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    a.help-center {
      margin: 0 10px 0 0;
      font-weight: normal;
      color: #555;
      border: 0;
      text-decoration: none;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        &:before {
          color: $theme-primary;
        }
      }
    }
  }
}
#banner-wrap {
  background: transparent url(../images/banners/banner-wrap.jpg) no-repeat 0 bottom/cover;
  h1 {
    line-height: 1em;
    font-size: 2em;
  }
  h2 {
    color: #bb2026;
  }
  .price {
    color: #004a80;
    text-shadow: none;
  }
  #banner {
    img.seal {
      display: none;
      vertical-align: middle;
      margin-left: 25px;
    }
    ul.check {
      vertical-align: middle;
      color: #686868;
      a {
        color: #004a80;
      }
    }
  }
}
#course-options-wrap {
  background: #89c4d6 url(../images/banner-wrap.jpg) no-repeat center;
  background-size: cover;
  padding-top: 20px;
  padding-bottom: 0;
  ul.check {
    li::before {
      color: red;
    }
  }
}
#cta-wrap {
  background: $theme-primary;
  color: #fff;
  padding: 10px 0;
  text-align: center;
  .cta-text {
    display: none;
    margin: 10px 15px;
    font-size: 1.7em;
    font-family: 'Roboto Slab', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    line-height: 1em;
    vertical-align: middle;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    text-align: left;
    .license {
      font-size: 16px;
      display: block;
    }
  }
  .cta-btn {
    margin: 10px 15px;
  }
  .price-display {
    display: inline-block;
    margin: 10px 15px;
    .only {
      display: inline-block;
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-right: 5px;
      vertical-align: middle;
      text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
    }
    .price {
      color: #ffffff;
      margin: 0;
    }
  }
}
#points-wrap {
  // background: linear-gradient(170deg, #004a80, #004a80 27%, #4faee5);
  background-color: #004a80;
  padding: 20px 0;
  h3 {
    margin-top: 10px;
    color: #555;
  }
}
#secure-wrap {
  background: #eef8fc;
  border-top: 1px dotted #62a5c2;
  border-bottom: 1px dotted #62a5c2;
}
#course-wrap {
  .yes {
    background: #fbfff2;
    border: 1px dotted #09f;
    border-radius: 5px;
    ul.check {
      li::before {
        color: red;
      }
    }
  }
}

#reviews {
  border-top: 1px solid #e5ecf4;
  background: #fafafa;
  blockquote {
    margin-bottom: 10px;
    font-size: 1.35em;
    line-height: 1.4em;
    font-weight: 400;
    color: #444;
    font-family: Georgia, 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    font-style: italic;
    p {
      color: #555;
      &:after,
      &:before {
        margin-right: 8px;
        display: inline-block;
        content: '\201C';
        color: #666666;
        font-family: serif;
        font-size: 36px;
        font-weight: 700;
        vertical-align: middle;
        font-style: normal;
      }
      &:after {
        margin-left: 8px;
        content: '\201D';
      }
      a {
        color: inherit;
        border: 0;
      }
    }
  }
  .more-reviews {
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    .shopperlink {
      margin: 0 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.top-question {
  a {
    p {
      color: #555555;
    }
  }
}
#content-wrap {
  .cta-btn {
    color: #bb2026;
    border-color: #bb2026;
    max-width: 275px;
    font-size: 18px;
  }
}

// custom dropdown CTA styles
.cta-dropdown {
  width: 220px;
  vertical-align: middle;
  display: inline-block;
  span.dropdown {
    padding: 14px;
    padding-bottom: 12px;
    color: #fff;
    font-weight: 400;
    background: $cta-red !important;
    border: 0;
    height: auto;
    line-height: 1;
    font-size: 21px;
    text-align: center;
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    border-radius: 4px;
    & + .drop {
      top: 47px;
      width: 300px !important;
      border-color: $cta-red;
      border-top: 1px solid $cta-red;
      border-top-right-radius: 4px;
      li a {
        color: #333 !important;
      }
    }
    &:after {
      display: none;
    }
    &:hover {
      background: lighten($cta-red, 7) !important;
    }
  }
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header a.logo {
    span.small {
      display: inline-block;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    width: 70%;
    margin-top: 10px;
    margin-bottom: 10px;
    img {
      display: block;
      float: left;
      max-width: 80px;
      margin-right: 15px;
    }
    span.big {
      display: inline-block;
      font-size: 1.9em;
    }
    span.small {
      display: inline-block;
    }
  }
}
@media only screen and (min-width: 56.25em) {
  /* roughtly 900px/16px */
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    // width: 55%;
    span.small {
      font-size: 18px;
    }
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 55%;
    span.small {
      font-size: 18px;
    }
  }
  #header #support {
    display: block;
    span.phone {
      display: inline-block;
    }
  }
  #banner-wrap {
    background-color: #000;
    background-position: -100px 50%;
  }
  #cta-wrap {
    .cta-text {
      display: inline-block;
    }
  }
  #course-wrap {
    .course {
      background: url(../images/teen.jpg) no-repeat right bottom;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner-wrap {
    #banner {
      img.seal {
        display: inline-block;
      }
    }
    .card {
      max-width: 570px;
    }
    .details {
      ul.check {
        max-width: 340px;
      }
    }
  }
}
